import { cn, formatDateToMMDDYYWithTime } from "@/lib/utils";
import { DragHandleProps } from "./Card.DragHandle";
import { DebugInfo } from "./Card.DebugInfo";
import { useState } from "react";
import { Card, Shop } from "@/types/v2";
import { getCardSection } from "@/lib/cards";

interface CardDetailsProps extends DragHandleProps {
  cardId: string;
  cardData: Card;
  primaryLabel?: string;
  shop?: Shop;
  shopId?: string;
  ticker: number;
}

export const CardDetails = ({
  cardData,
  shop,
  dndAttributes,
  dndListeners,
}: CardDetailsProps) => {
  const [open, setOpen] = useState(false);

  const formattedDate = formatDateToMMDDYYWithTime(
    cardData?.promisedTime ?? null
  );

  if (!shop) return null;

  return (
    <div
      className="px-1 flex items-center space-y-1  w-full"
      onDoubleClick={() => setOpen(true)}
    >
      <DebugInfo cardData={cardData} open={open} setOpen={setOpen} />

      <div
        {...dndAttributes}
        {...dndListeners}
        className="min-h-5 cursor-grab border-none p-0 m-0 text-left  w-full"
      >
        <div className="flex flex-row justify-between">
          <div className="">
            <p
              className={cn(
                "mt-[-3px] mb-[4px] overflow-hidden",
                shop?.cardLabelWrap === "no" ? "text-nowrap" : ""
              )}
            >
              <span>{getCardSection(cardData, shop, "secondaryField")}</span>
            </p>

            <p
              className={cn(
                "mt-[-3px] mb-[4px] overflow-hidden",
                shop?.cardLabelWrap === "no" ? "text-nowrap" : ""
              )}
            >
              {getCardSection(cardData, shop, "tertiaryField")}
            </p>
            <p className="mt-[-3px] mb-[4px] overflow-hidden text-nowrap text-[12px]">
              {formattedDate?.includes("NaN") ? "" : formattedDate}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
