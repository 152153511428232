import { clsx, type ClassValue } from "clsx";
import { Timestamp } from "firebase/firestore";
import { twMerge } from "tailwind-merge";
import {
  differenceInDays,
  format,
  isToday,
  isValid,
  isYesterday,
  parse,
  parseISO,
} from "date-fns";

/**
 * Combines multiple class names into a single string, merging any overlapping Tailwind CSS classes.
 * @param {ClassValue[]} inputs - An array of class names or class name objects to combine.
 * @returns {string} The combined class string with merged Tailwind CSS classes.
 */
export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

/**
 * Adds a value to an array if it does not already exist within that array.
 * The value is appended to the end of the array, maintaining the initial order of the other elements.
 * @template T
 * @param {T} value - The value to be added to the array.
 * @param {T[]} array - The array to which the value will be added.
 * @returns {T[]} A new array containing the original elements and the added value if it was not already present.
 */
export function arrayUnion<T>(value: T, array: T[]): T[] {
  return [...array.filter((item) => item !== value), value];
}

/**
 * Removes all instances of a specified value from an array and returns a new array.
 * @template T
 * @param {T} value - The value to be removed from the array.
 * @param {T[]} array - The array from which to remove the specified value.
 * @returns {T[]} A new array with the specified value removed.
 */
export function arrayRemove<T>(value: T, array: T[]): T[] {
  return [...array.filter((item) => item !== value)];
}

/**
 * Returns an array containing only the unique values from the input array.
 * Duplicate entries in the input array will be removed in the returned array.
 * @param {T[]} array - The input array from which unique values should be extracted.
 * @returns {T[]} An array containing all unique values from the input array.
 */
export function getUniqueValues<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

/**
 * Calculates the brightness of a HEX color.
 * @param {string} color - The HEX color code in the format #RRGGBB.
 * @returns {number} The brightness value ranging from 0 to 255.
 */
function getBrightness(color: string): number {
  const hexColor = color.replace(/^#/, "");
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);
  return (r * 299 + g * 587 + b * 114) / 1000;
}

// Determine the text color based on the background color's brightness
/**
 * Determines the appropriate text color (black or white) based on the brightness of the specified background color.
 * @param {string | "transparent"} color - The background color input which can be a color string or "transparent".
 * @returns {string | undefined} - Returns "black" or "white" depending on the brightness of the background color. Returns undefined if the brightness cannot be calculated.
 */
export function getTextColor(
  color: string | "transparent"
): string | undefined {
  const brightness = getBrightness(color);
  if (isNaN(brightness)) return undefined;
  return getBrightness(color) > 128 ? "black" : "white";
}

/**
 * Formats an array of strings into a single string, following specific grammar rules.
 * @param {string[]} arr - The array of strings to format.
 * @returns {string} The formatted string.
 */
export function formatArrayAsString(arr: string[]): string {
  const arrToFormat = [...arr];
  if (arrToFormat.length === 1) {
    return arrToFormat[0];
  } else if (arrToFormat.length === 2) {
    return arrToFormat.join(" and ");
  } else {
    const last = arrToFormat.pop();
    return `${arrToFormat.join(", ")}, and ${last}`;
  }
}

/**
 * Capitalizes the first letter of a given word.
 * @param {string} word - The word to be capitalized.
 * @returns {string} The capitalized word.
 */
export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

/**
 * Converts Firestore time formats into JavaScript Date objects.
 * @param {Timestamp|Date|undefined|null|string} firestoreTime - The Firestore time that needs to be converted.
 * @returns {Date|undefined} Converted JavaScript Date object, or undefined if the input is invalid.
 */
export function firestoreToDateTime(
  firestoreTime: Timestamp | Date | undefined | null | string
): Date | undefined {
  if (!firestoreTime) {
    return undefined;
  }
  if (typeof firestoreTime === "string") {
    const returnDate = parse(firestoreTime, "yyyy-MM-dd", new Date());
    if (!isValid(returnDate)) {
      return parseISO(firestoreTime);
    }
    return returnDate;
  }
  if (firestoreTime instanceof Date) return firestoreTime;
  return firestoreTime?.toDate();
}

/**
 * Determines if the given date is in the past relative to the current date.
 * @param {string | Timestamp | Date | null | undefined} dateToCompare - The date to compare. It can be a string, a Timestamp, a Date object, or null/undefined.
 * @returns {boolean} - Returns true if the date is in the past; otherwise, returns false.
 */
export function isPast(
  dateToCompare: string | Timestamp | Date | null | undefined
): boolean {
  if (!dateToCompare) return false;

  if (typeof dateToCompare === "string") {
    return new Date(dateToCompare) <= new Date();
  }

  const convertedDate = firestoreToDateTime(dateToCompare);
  if (!convertedDate) return false;
  return convertedDate <= new Date();
}

/**
 * Formats a given date string into the MM-DD-YY format with time appended.
 * @param {null|string} dateString - The date string to format. If null, the method returns null.
 * @returns {null|string} The formatted date string in the format MM-DD-YY @ h:mm[a|p],
 *                       or null if the input date string is null.
 */
export function formatDateToMMDDYYWithTime(
  dateString: null | string
): null | string {
  if (dateString === null) {
    return null;
  }

  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Month is zero-based, so +1
  const day = date.getDate();
  const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  let period = "am";
  if (hours >= 12) {
    period = "pm";
    if (hours > 12) hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const formattedTime = `${hours}:${minutes}${period}`;

  return `${month}/${day}/${year} @ ${formattedTime}`;
}

/**
 * Extracts the initials from a given name string and returns them in uppercase.
 * @param {string | undefined} name - The name from which to extract initials.
 * @returns {string} - The uppercase initials of the provided name.
 */
export function getInitials(name: string | undefined): string {
  if (!name) return "";
  return name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();
}

/**
 * Rounds a number to a specified number of decimal places and returns it as a string.
 * Optionally, can remove trailing zeros after the decimal point if the number is an integer.
 * @param {number} value - The number to be rounded.
 * @param {number} decimalPlaces - The number of decimal places to round to.
 * @param {boolean} [keepDecimals] - Whether to keep trailing zeros after the decimal point.
 * @returns {string} The rounded number as a string.
 */
function roundAsString(
  value: number,
  decimalPlaces: number,
  keepDecimals: boolean = true
): string {
  const roundedValue = value.toFixed(decimalPlaces);
  return keepDecimals && value % 1 !== 0
    ? roundedValue
    : parseFloat(roundedValue).toString();
}

/**
 * Converts a given time in minutes to milliseconds.
 * @param {string | number} minutes - The time in minutes, can be provided as a number or a string representation of a number.
 * @returns {number} The equivalent time in milliseconds.
 */
export function minutesToMilliseconds(minutes: string | number): number {
  if (typeof minutes === "number") {
    return minutes * 60 * 1000;
  }
  return parseFloat(minutes) * 60 * 1000;
}

/**
 * Converts the given time from minutes to hours.
 * @param {string|number} minutes - The time in minutes to be converted. Can be provided as a number or string.
 * @param {boolean} [keepDecimals] - Whether to keep decimals in the resulting hours. Defaults to true.
 * @returns {string} The time converted to hours, represented as a string.
 */
export function convertMinutesToHours(
  minutes: string | number,
  keepDecimals: boolean = true
): string {
  if (typeof minutes === "number") {
    return roundAsString(minutes / 60, 2, keepDecimals);
  }
  return roundAsString(parseFloat(minutes) / 60, 2, keepDecimals);
}

/**
 * Converts the given hours into minutes. The input can be either a string or a number.
 * @param {string|number} hours - The number of hours to be converted to minutes.
 * @param {boolean} [keepDecimals] - Optional. If true, keeps the decimal part in the result.
 * @returns {string} The equivalent minutes as a string.
 */
export function convertHoursToMinutes(
  hours: string | number,
  keepDecimals: boolean = true
): string {
  if (typeof hours === "number") {
    return roundAsString(hours * 60, 2, keepDecimals);
  }
  return roundAsString(parseFloat(hours) * 60, 2, keepDecimals);
}

/**
 * Formats a given timestamp into a human-readable date string.
 * @param {Date} timestamp - The date object to be formatted.
 * @param {boolean} asDate - Optional: Return the formatted date as date only.
 * @returns {string} A formatted date string depending on how recent the date is.
 *                   - Returns time if the date is today.
 *                   - Returns "Yesterday at time" if the date is yesterday.
 *                   - Returns the day of the week and time if the date is within the last 7 days.
 *                   - Returns a full date and time if the date is older than 7 days.
 */
export function formatDate(timestamp: Date, asDate: boolean = false): string {
  if (asDate) {
    if (isToday(timestamp)) {
      return "Today";
    } else if (isYesterday(timestamp)) {
      return "Yesterday";
    } else if (differenceInDays(Date.now(), timestamp) < 7) {
      return format(timestamp, "EEEE, MMM dd");
    } else {
      return format(timestamp, "MMM d, yyyy");
    }
  }

  if (isToday(timestamp)) {
    return format(timestamp, "h:mm a");
  } else if (isYesterday(timestamp)) {
    return `Yesterday at ${format(timestamp, "h:mm a")}`;
  } else if (differenceInDays(Date.now(), timestamp) < 7) {
    return format(timestamp, "EEEE 'at' h:mm a");
  } else {
    return format(timestamp, "MMM d, yyyy 'at' h:mm a");
  }
}

/**
 * Converts a duration in milliseconds into an ISO 8601 formatted string (HH:mm:ss or mm:ss).
 * @param {number} milliseconds - Duration in milliseconds to be converted.
 * @returns {string} The formatted time as a string in ISO 8601 duration format.
 */
export function formatToISODuration(milliseconds: number) {
  const secNum = Math.floor(milliseconds / 1000);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum % 3600) / 60);
  const seconds = Math.floor(secNum % 60);

  if (hours > 0) {
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  } else {
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  }
}
