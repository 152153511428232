import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Base/Popover";
import { COLOR_PICKER_COLORS } from "@/lib/const";
import { useState } from "react";
import { ColorButton } from "./ColorButton";
import { cn } from "@/lib/utils";
import { HexColor } from "@/types/v2";

export interface ColorPickerProps {
  color: HexColor;
  onColorChange?: (color: HexColor) => void;
  disabled?: boolean;
}

export const ColorPicker = ({
  color,
  onColorChange,
  disabled,
}: ColorPickerProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        disabled={disabled}
        className={cn(disabled && "opacity-50")}
        asChild
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ColorButton style={{ backgroundColor: color }} size="xs" />
      </PopoverTrigger>
      <PopoverContent
        onMouseDown={(e) => e.stopPropagation()}
        className="w-40 p-1"
      >
        <div className="grid grid-cols-4 gap-1">
          {COLOR_PICKER_COLORS.map((color) => (
            <ColorButton
              key={color.label}
              style={{ backgroundColor: color.hex }}
              onClick={() => {
                onColorChange?.(color.hex);
                setOpen(false);
              }}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
