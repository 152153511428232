import {
  ChartColumnIcon,
  MoreVerticalIcon,
  PencilIcon,
  TrashIcon,
  UndoIcon,
} from "lucide-react";
import { Fragment, useState } from "react";
import { removeCard } from "@/lib/cards";
import { Card, Employee, PatchCardDto, Shop } from "@/types/v2";
import { card } from "@/models/cards";
import { CardRemoveDialog } from "@/components/Dashboard/components/Card/CardRemoveDialog";
import { CardFormDialog } from "@/components/Dashboard/components/Card/CardFormDialog";
import { CardMetrics } from "@/components/Dashboard/components/Card/Card.Metrics";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/Base/DropdownMenu";
import { DropdownMenuLabel } from "@radix-ui/react-dropdown-menu";

export interface CardMenuProps {
  cardId: string;
  shopId: string;
  cardData: Card;
  employees?: Employee[];
  disabled?: boolean;
  shop: Shop;
}

export const CardMenu = ({
  cardId,
  shopId,
  cardData,
  disabled,
  employees,
  shop,
}: CardMenuProps) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialog, setDialog] = useState<"form" | "remove" | "metrics">("form");

  const handleRemoveCard = async () => {
    await removeCard(shopId, cardId);
    setOpen(false);
  };

  const handleEditCard = async (values: PatchCardDto) => {
    await card.update(shopId, cardId, {
      ...values,
      _isSoftMigrated: cardData._isSoftMigrated,
    });
    setOpen(false);
    return;
  };

  const handleResetCard = async () => {
    await card.update(shopId, cardId, {
      _isSoftMigrated: cardData._isSoftMigrated,
      automationAction: null,
      automationActionData: null,
      automationActionStartAt: null,
      automationActionExpireAt: null,
    });
    setOpen(false);
    return;
  };

  const handleOpenDialog = (dialog: "form" | "remove" | "metrics") => {
    setDialog(dialog);
    setDialogOpen(true);
  };
  return (
    <Fragment>
      <CardFormDialog
        title="Edit"
        buttonTitle="Edit Card"
        description="Edit card. Card fields are determined by the card configuration."
        cardData={cardData}
        onSubmit={handleEditCard}
        externalTrigger
        open={dialogOpen && dialog === "form"}
        onOpenChange={setDialogOpen}
      />
      <CardRemoveDialog
        onRemove={handleRemoveCard}
        externalTrigger
        open={dialogOpen && dialog === "remove"}
        onOpenChange={setDialogOpen}
      />
      <CardMetrics
        cardData={cardData}
        externalTrigger
        open={dialogOpen && dialog === "metrics"}
        onOpenChange={setDialogOpen}
        employees={employees}
        shop={shop}
      />
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger
          disabled={disabled}
          className="cursor-pointer z-5 p-1 rounded-sm text-accent hover:bg-white/50 disabled:cursor-default disabled:hover:bg-transparent"
          onMouseDown={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MoreVerticalIcon size={14} />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          onMouseDown={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          className="flex flex-col gap-2 p-2"
        >
          <DropdownMenuLabel>Card Menu</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {cardData.source === "manual" && (
            <>
              <DropdownMenuItem onClick={() => handleOpenDialog("form")}>
                <PencilIcon size={14} />
                <span>Edit</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleOpenDialog("remove")}>
                <TrashIcon />
                <span>Remove</span>
              </DropdownMenuItem>

              <DropdownMenuSeparator />
            </>
          )}
          {!!cardData.automationAction && (
            <Fragment>
              <DropdownMenuItem onClick={handleResetCard}>
                <UndoIcon />
                <span>Reset Automation</span>
              </DropdownMenuItem>

              <DropdownMenuSeparator />
            </Fragment>
          )}
          <DropdownMenuItem onClick={() => handleOpenDialog("metrics")}>
            <ChartColumnIcon />
            <span>Metrics</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </Fragment>
  );
};
