import {
  CardMenu,
  CardMenuProps,
} from "@/components/Dashboard/components/Card/Card.Menu";
import { CardMetricsProps } from "@/components/Dashboard/components/Card/Card.Metrics";
import { CardTechSALabel, CardTechSALabelProps } from "./Card.TechSALabel";

export interface CardSidebarProps
  extends CardMetricsProps,
    CardMenuProps,
    CardTechSALabelProps {}

export const CardSidebar = ({
  cardData,
  shopId,
  employees,
  disabled,
  shop,
}: CardSidebarProps) => {
  return (
    <div className="flex flex-col justify-start items-center absolute top-0.5 right-0.5">
      <CardTechSALabel cardData={cardData} employees={employees} />
      <CardMenu
        cardId={cardData.id}
        shopId={shopId}
        cardData={cardData}
        disabled={disabled}
        employees={employees}
        shop={shop}
      />
    </div>
  );
};
