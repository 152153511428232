import { useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import { Button } from "@/components/Base/Button";
import { BuilderDialog } from "@/components/Automations/BuilderDialog";
import {
  AUTOMATION_ACTION_OPTIONS,
  AUTOMATION_TRIGGER_OPTIONS,
} from "@/lib/const";
import {
  BuilderAction,
  BuilderTrigger,
  TriggerCondition,
  CreateAutomationDto,
} from "@/types/v2/Automation";
import { Trigger } from "@/components/Automations/Trigger";
import { Action } from "@/components/Automations/Action";
import { PlusIcon } from "lucide-react";
import { useDynamicOptions } from "@/hooks/useDynamicOptions";

interface AutomationDialogProps {
  onSubmit?: (automation: CreateAutomationDto) => Promise<void>;
}

export const AutomationDialog = ({ onSubmit }: AutomationDialogProps) => {
  const [open, setOpen] = useState(false);
  const [showTriggerBuilderDialog, setShowTriggerBuilderDialog] =
    useState(true);
  const [showActionBuilderDialog, setShowActionBuilderDialog] = useState(true);

  const [trigger, setTrigger] = useState<BuilderTrigger[]>();
  const [action, setAction] = useState<BuilderAction[]>();
  const { generateOptions } = useDynamicOptions();

  const handleTriggerClicked = (optionId: string, secondaryId?: string) => {
    const primaryTrigger = AUTOMATION_TRIGGER_OPTIONS.find(
      (option) => option.id === optionId
    );
    if (!primaryTrigger) return;
    const secondaryTrigger = primaryTrigger.conditionalTriggers?.find(
      (option) => option.id === secondaryId
    );
    if (secondaryTrigger) {
      setTrigger((prevState) =>
        prevState ? [...prevState, secondaryTrigger] : [secondaryTrigger]
      );
    } else {
      setTrigger((prevState) =>
        prevState ? [...prevState, primaryTrigger] : [primaryTrigger]
      );
    }
  };

  const handleRemoveTrigger = (index: number) => {
    if (index === 0) {
      setTrigger(undefined);
      setShowTriggerBuilderDialog(true);
    } else {
      setTrigger((prevState) => prevState?.filter((_, i) => i !== index));
    }
  };

  const handleTriggerValueChange = (index: number, value: string) => {
    if (trigger) {
      const newTrigger = trigger[index];
      newTrigger.triggerValue = value;
      setTrigger((prevState) =>
        prevState
          ? [...prevState.filter((_, i) => i !== index), newTrigger]
          : [newTrigger]
      );
    }
  };

  const handleTriggerConditionalChange = (
    index: number,
    value: TriggerCondition
  ) => {
    if (trigger) {
      const newTrigger = trigger[index];
      newTrigger.triggerCondition = value;
      setTrigger((prevState) =>
        prevState
          ? [...prevState.filter((_, i) => i !== index), newTrigger]
          : [newTrigger]
      );
    }
  };

  const triggerButtons = {
    sections: [
      {
        title: "Shop Hero",
        buttons: AUTOMATION_TRIGGER_OPTIONS.map((option) => ({
          label: option.buttonOptions.text,
          icon: option.buttonOptions.icon,
          onClick: () => handleTriggerClicked(option.id),
        })),
      },
    ],
  };

  const conditionButtons = useMemo(
    () =>
      trigger?.map((t) =>
        t.conditionalTriggers &&
        t.conditionalTriggers.filter(
          (ct) => !trigger?.map((tId) => tId.id).includes(ct.id)
        ).length > 0
          ? {
              sections: [
                {
                  title: "Shop Hero",
                  buttons:
                    t?.conditionalTriggers
                      .filter(
                        (ct) => !trigger?.map((tId) => tId.id).includes(ct.id)
                      )
                      .map((option) => ({
                        label: option.buttonOptions.text,
                        icon: option.buttonOptions.icon,
                        onClick: () => handleTriggerClicked(t.id, option.id),
                      })) ?? [],
                },
              ],
            }
          : undefined
      ),
    [trigger]
  );

  const additionalActions = useMemo(
    () =>
      action?.map((t) =>
        t.subsequentActions &&
        t.subsequentActions.filter(
          (ct) => !action?.map((tId) => tId.id).includes(ct.id)
        ).length > 0
          ? {
              sections: [
                {
                  title: "Shop Hero",
                  buttons:
                    t?.subsequentActions
                      .filter(
                        (ct) => !action?.map((tId) => tId.id).includes(ct.id)
                      )
                      .map((option) => ({
                        label: option.buttonOptions.text,
                        icon: option.buttonOptions.icon,
                        onClick: () => handleActionClicked(t.id, option.id),
                      })) ?? [],
                },
              ],
            }
          : undefined
      ),
    [action]
  );

  const handleActionClicked = (optionId: string, secondaryId?: string) => {
    const primaryAction = AUTOMATION_ACTION_OPTIONS.find(
      (option) => option.id === optionId
    );
    if (!primaryAction) return;
    const secondaryAction = primaryAction.subsequentActions?.find(
      (option) => option.id === secondaryId
    );
    if (secondaryAction) {
      const requiredActions =
        secondaryAction.subsequentActions?.filter((a) => a.required) ?? [];
      setAction((prevState) =>
        prevState
          ? [...prevState, secondaryAction, ...requiredActions]
          : [secondaryAction, ...requiredActions]
      );
    } else {
      const requiredActions =
        primaryAction.subsequentActions?.filter((a) => a.required) ?? [];
      setAction((prevState) =>
        prevState
          ? [...prevState, primaryAction, ...requiredActions]
          : [primaryAction, ...requiredActions]
      );
    }
  };

  const handleRemoveAction = (index: number) => {
    if (index === 0) {
      setAction(undefined);
      setShowActionBuilderDialog(true);
    } else {
      setAction((prevState) => prevState?.filter((_, i) => i !== index));
    }
  };

  const handleActionValueChange = (index: number, value: string) => {
    if (action) {
      const newAction = action[index];
      newAction.actionValue = value;
      setAction((prevState) =>
        prevState
          ? [...prevState.filter((_, i) => i !== index), newAction]
          : [newAction]
      );
    }
  };

  const actionButtons = {
    sections: [
      {
        title: "Shop Hero",
        buttons: AUTOMATION_ACTION_OPTIONS.map((option) => ({
          label: option.buttonOptions.text,
          icon: option.buttonOptions.icon,
          onClick: () => handleActionClicked(option.id),
        })),
      },
    ],
  };

  const handleTriggerSelected = () => {
    setShowTriggerBuilderDialog(false);
  };

  const handleActionSelected = () => {
    setShowActionBuilderDialog(false);
  };

  const resetDialog = () => {
    setTrigger(undefined);
    setAction(undefined);
    setShowTriggerBuilderDialog(true);
    setShowActionBuilderDialog(true);
  };

  const handleSave = () => {
    if (!trigger || !action) {
      return;
    }
    const automation: CreateAutomationDto = {
      triggersConditional: "AND",
      triggers: trigger.map((trig) => ({
        templateId: trig.id,
        triggerObject: trig.triggerObject,
        triggerEventType: trig.triggerEventType ?? "onUpdate",
        triggerField: trig.triggerField,
        triggerFieldChange: trig.triggerFieldChange,
        triggerCondition: trig.triggerCondition,
        triggerValue: trig.triggerValue,
      })),
      actions: action.map((a) => ({
        templateId: a.id,
        actionObject: a.actionObject,
        actionEventType: a.actionEventType,
        actionField: a.actionField,
        actionValue: a.actionValue,
        actionObjectIdentifier: "self",
      })),
    };
    onSubmit?.(automation);
    setOpen(false);
    resetDialog();
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      resetDialog();
    }
    setOpen(open);
  };

  return (
    <Dialog onOpenChange={handleOpenChange} open={open}>
      <DialogTrigger asChild>
        <Button variant={"accent"} onClick={() => setOpen(true)}>
          Create Automation
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{"Create Automation"}</DialogTitle>
        </DialogHeader>
        <DialogDescription></DialogDescription>
        {showTriggerBuilderDialog && (
          <BuilderDialog
            buttonTitle={"When this happens"}
            title={"Trigger"}
            builderListProps={triggerButtons}
            onSubmit={async () => handleTriggerSelected()}
          />
        )}
        {trigger &&
          trigger.map((t, i) => (
            <Trigger
              key={t.id}
              label={t.buttonOptions.selectedText ?? t.buttonOptions.text}
              conditionalOptions={t.triggerConditionOptions}
              valuePlaceholder={
                t.triggerField ? t.triggerPlaceholder : undefined
              }
              onValueChange={
                t.triggerField &&
                !t.conditionalTriggers &&
                t.triggerValueEditable
                  ? (value) => handleTriggerValueChange(i, value)
                  : undefined
              }
              valueOptions={generateOptions(
                t.triggerValueOptions,
                t.triggerValueOptionsDynamic
              )}
              onConditionalChange={
                t.triggerConditionOptions
                  ? (value) => handleTriggerConditionalChange(i, value)
                  : undefined
              }
              onAdd={undefined}
              canAdd={conditionButtons && conditionButtons[i] !== undefined}
              addComponent={
                conditionButtons &&
                conditionButtons[i] !== undefined && (
                  <BuilderDialog
                    buttonTitle={<PlusIcon />}
                    buttonSize={"icon"}
                    disabled={!trigger}
                    title={"Select a condition"}
                    builderListProps={conditionButtons[i]}
                    onSubmit={async () => console.log(i)}
                  />
                )
              }
              onRemove={() => handleRemoveTrigger(i)}
            />
          ))}
        {showActionBuilderDialog && (
          <BuilderDialog
            buttonTitle={"Then do this"}
            disabled={!trigger}
            title={"Select an Action"}
            builderListProps={actionButtons}
            onSubmit={async () => handleActionSelected()}
          />
        )}
        {action &&
          action.map((a, i) => (
            <Action
              key={a.id}
              label={a.buttonOptions.selectedText ?? a.buttonOptions.text}
              valuePlaceholder={a.actionField ? a.actionPlaceholder : undefined}
              valueOptions={generateOptions(
                a.actionValueOptions,
                a.actionValueOptionsDynamic
              )}
              onValueChange={
                a.actionField && a.actionValueEditable
                  ? (value) => handleActionValueChange(i, value)
                  : undefined
              }
              onAdd={undefined}
              canAdd={additionalActions && additionalActions[i] !== undefined}
              addComponent={
                additionalActions &&
                additionalActions[i] !== undefined && (
                  <BuilderDialog
                    buttonTitle={<PlusIcon />}
                    buttonSize={"icon"}
                    disabled={!trigger}
                    title={"Select additional actions"}
                    builderListProps={additionalActions[i]}
                    onSubmit={async () => console.log(i)}
                  />
                )
              }
              canRemove={!a.required}
              onRemove={() => handleRemoveAction(i)}
            />
          ))}
        <DialogFooter>
          <Button variant={"secondary"} onClick={() => handleOpenChange(false)}>
            Cancel
          </Button>
          <Button type={"submit"} onClick={() => handleSave()}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
