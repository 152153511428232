import { ReactNode, useEffect, useState } from "react";
import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import { CardForm, CardFormProps } from "./CardForm";
import { PatchCardDto } from "@/types/v2";

interface CardFormDialogProps<T> extends CardFormProps<T> {
  title?: string;
  buttonTitle?: string;
  cardData?: PatchCardDto;
  description?: string;
  trigger?: ReactNode;
  externalTrigger?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}
export const CardFormDialog = <T,>({
  title,
  buttonTitle,
  cardData,
  onSubmit,
  description,
  trigger,
  externalTrigger,
  open,
  onOpenChange,
}: CardFormDialogProps<T>) => {
  const [isOpen, setIsOpen] = useState(open ?? false);

  // Sync local state with the controlled `open` prop
  useEffect(() => {
    if (typeof open === "boolean") {
      setIsOpen(open);
    }
  }, [open]);

  // Handle the dialog's open state changes
  const handleOpenChange = (newOpen: boolean) => {
    setIsOpen(newOpen);
    onOpenChange?.(newOpen); // Notify parent if `onOpenChange` is provided
  };

  const handleSave = async (values: T) => {
    return onSubmit(values).finally(() => handleOpenChange(false));
  };

  const handleCancel = () => {
    handleOpenChange(false);
  };

  return (
    <Dialog onOpenChange={handleOpenChange} open={isOpen}>
      {trigger && !externalTrigger ? (
        // Use custom trigger if provided
        <DialogTrigger asChild>{trigger}</DialogTrigger>
      ) : (
        // Default trigger button
        !externalTrigger && (
          <DialogTrigger asChild>
            <Button onClick={() => handleOpenChange(true)}>
              {buttonTitle ?? "Create Card"}
            </Button>
          </DialogTrigger>
        )
      )}

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title ?? "Create a New Card"}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {description ??
            "Create a new card. Fields here are determined by the card configuration."}
        </DialogDescription>
        <div className="flex flex-col gap-2">
          <CardForm
            cancelButton={
              <Button
                type="button"
                variant={"secondary"}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            }
            submitButton={<Button type="submit">Save</Button>}
            cardData={cardData}
            onSubmit={handleSave}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
