import { useStore } from "@/stores/useStore";
import { Feature, useFeature } from "flagged";
import { cn } from "@/lib/utils";
import { DragHandleProps } from "./Card.DragHandle";
import { DebugInfo } from "./Card.DebugInfo";
import { useMemo, useState } from "react";
import { Card, Integration } from "@/types/v2";
import { ClassNameValue } from "tailwind-merge";
import { createSubdomainUrl } from "@/lib/createSubdomainUrl";

interface CardHeaderProps extends DragHandleProps {
  cardId: string;
  cardData: Card;
  primaryLabel?: string;
  disabled?: boolean;
}

interface UnlinkedLabelProps extends DragHandleProps {
  primaryLabel: string | undefined;
}

const UnlinkedLabel = ({
  dndAttributes,
  dndListeners,
  primaryLabel,
}: UnlinkedLabelProps) => (
  <button
    className="text-left overflow-x-hidden h-auto whitespace-nowrap cursor-grab"
    {...dndAttributes}
    {...dndListeners}
  >
    <p>
      <span
        className={cn(
          "font-bold",
          primaryLabel ? getPrimaryTextSize(primaryLabel) : "text-2xl"
        )}
      >
        {primaryLabel}
      </span>
    </p>
  </button>
);

interface LinkedLabelProps {
  shopId: string;
  subdomain: string;
  cardId: string;
  primaryLabel: string | undefined;
  integration: Integration;
}

const getPrimaryTextSize = (label: string): ClassNameValue => {
  if (label.length > 9) {
    return "text-base";
  } else if (label.length > 8) {
    return "text-lg";
  } else if (label.length > 7) {
    return "text-xl";
  } else {
    return "text-2xl";
  }
};

const LinkedLabel = ({
  shopId,
  subdomain,
  cardId,
  primaryLabel,
  integration,
}: LinkedLabelProps) => (
  <a
    className={cn(
      "w-full overflow-x-hidden h-auto whitespace-nowrap font-bold",
      primaryLabel ? getPrimaryTextSize(primaryLabel) : "text-2xl"
    )}
    rel="noreferrer"
    target="_blank"
    href={
      integration.integration === "tekmetric"
        ? `${integration.linkUrl}/admin/shop/${shopId}/repair-orders/${cardId}/estimate`
        : integration.integration === "shopware"
          ? `${createSubdomainUrl(subdomain, integration.linkUrl)}/work_orders/${cardId}`
          : ""
    }
  >
    {primaryLabel}
  </a>
);

interface ManualLabelProps extends DragHandleProps {
  primaryLabel: string | undefined;
}

const ManualLabel = ({
  primaryLabel,
  dndListeners,
  dndAttributes,
}: ManualLabelProps) => (
  <div
    className="w-full relative cursor-grab"
    {...dndAttributes}
    {...dndListeners}
  >
    <h3
      className={cn(
        "w-full overflow-x-hidden h-auto whitespace-nowrap font-bold",
        primaryLabel ? getPrimaryTextSize(primaryLabel) : "text-2xl"
      )}
    >
      {primaryLabel}
    </h3>
  </div>
);

export const CardHeader = ({
  cardId,
  cardData,
  dndAttributes,
  dndListeners,
  primaryLabel,
}: CardHeaderProps) => {
  const shopId = useStore((s) => s.shop?.shopId);
  const shop = useStore((s) => s.shop);
  const subdomain = useStore((s) => s.shop?.integrationSubdomain);
  const integration = useStore((s) => s.integration);
  const [open, setOpen] = useState(false);

  const isManualCardOnlyEnabled = useFeature("isManualCardOnlyEnabled");

  const showMenu = useMemo(
    () => cardData.source === "manual" || !!cardData.automationAction,
    [cardData]
  );

  return (
    <div className="px-1 min-h-4" onDoubleClick={() => setOpen(true)}>
      <DebugInfo
        cardData={cardData}
        open={open}
        setOpen={setOpen}
        shop={shop}
        firestoreId={shop?.id}
      />

      <div className="w-full relative">
        <h3
          className={cn(
            "text-2xl font-bold overflow-x-hidden h-auto whitespace-nowrap",
            showMenu ? "mr-5" : ""
          )}
        >
          {isManualCardOnlyEnabled ? (
            <ManualLabel
              primaryLabel={primaryLabel}
              dndAttributes={dndAttributes}
              dndListeners={dndListeners}
            />
          ) : (
            <Feature name="isCardPrimaryLinkEnabled">
              {(isLinkEnabled) =>
                isLinkEnabled &&
                shopId &&
                integration &&
                cardData.source !== "manual" ? (
                  <LinkedLabel
                    cardId={cardId}
                    subdomain={subdomain ?? ""}
                    shopId={shopId}
                    primaryLabel={primaryLabel}
                    integration={integration}
                  />
                ) : (
                  <UnlinkedLabel
                    dndAttributes={dndAttributes}
                    dndListeners={dndListeners}
                    primaryLabel={primaryLabel}
                  />
                )
              }
            </Feature>
          )}
        </h3>
      </div>
    </div>
  );
};
