import { SquareIcon } from "lucide-react";

export interface CardTimerProps {
  timeRemaining: number;
  stopTimer: () => Promise<void>;
  loading: boolean;
}
export const CardTimer = ({ loading, ...props }: CardTimerProps) => {
  ////////////////////////////////////////////////////////////
  // const formatToISODuration = (milliseconds)
  ////////////////////////////////////////////////////////////
  // This function will take in a number of milliseconds and return a string in the format of HH:MM:SS
  // If the number of hours is greater than 0, it will return HH:MM:SS
  // If the number of hours is less than 0, it will return MM:SS
  // If the number of minutes is less than 10, it will add a 0 in front of the number
  ////////////////////////////////////////////////////////////
  const formatToISODuration = (milliseconds: number) => {
    const secNum = Math.floor(milliseconds / 1000);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum % 3600) / 60);
    const seconds = Math.floor(secNum % 60);

    if (hours > 0) {
      return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`;
    } else {
      return `${minutes < 10 ? "0" + minutes : minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`;
    }
  };

  ////////////////////////////////////////////////////////////
  // const millisecondsToHours = (milliseconds)
  ////////////////////////////////////////////////////////////
  // This function will take in a number of milliseconds and return the number of hours
  ////////////////////////////////////////////////////////////
  const millisecondsToHours = (milliseconds: number) => {
    const sec_num = Math.floor(milliseconds / 1000);
    const hours = Math.floor(sec_num / 3600);
    return hours;
  };

  ////////////////////////////////////////////////////////////
  // const handleStopTimer = ()
  ////////////////////////////////////////////////////////////
  // This function will handle stopping the timer
  // It will set the loading state to true
  // It will call the stopTimer function from props
  // It will set the loading state to false
  ////////////////////////////////////////////////////////////
  const handleStopTimer = () => {
    props.stopTimer();
  };

  return (
    <div className="p-0 pb-[0.5px] flex justify-between w-full items-center self-center">
      {!loading && (
        <button
          className="bg-none flex flex-row self-center"
          onClick={handleStopTimer}
        >
          <SquareIcon size={14} />
        </button>
      )}
      <p
        className="self-middle p-0 m-0"
        style={{
          fontSize: millisecondsToHours(props.timeRemaining) > 0 ? 10 : 14,
        }}
      >{`${formatToISODuration(props.timeRemaining)}`}</p>
    </div>
  );
};
