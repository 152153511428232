import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Base/Dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Base/Popover";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { capitalize } from "@/lib/utils";
import { Card, Shop } from "@/types/v2";
import { CopyIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { card } from "@/models/cards";

interface DebugInfoProps {
  cardData: Card;
  setOpen: (open: boolean) => void;
  open: boolean;
  shop?: Shop;
  firestoreId?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const CardField = ({ name, value }: { name: string; value: any }) => {
  const [copiedText, copy] = useCopyToClipboard();
  const [copyPopoverOpen, setCopyPopoverOpen] = useState(false);
  const [copyResults, setCopyResults] = useState({ success: false, error: "" });

  const handleCopy = (text: string) => {
    copy(text)
      .then(() => {
        setCopyResults({ success: true, error: "" });
      })
      .catch((error) => {
        setCopyResults({ success: false, error: error });
        console.error(error);
      })
      .finally(() => {
        setTimeout(() => {
          setCopyPopoverOpen(false);
        }, 2000);
      });
  };
  return (
    <div className="flex flex-row justify-start items-center gap-4">
      <div className="font-bold">{name}:</div>
      <pre className={"text-wrap"}>{value ?? "N/A"}</pre>
      <div className="flex-grow" />
      <Popover open={copyPopoverOpen} onOpenChange={setCopyPopoverOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="smIcon"
            onClick={() => handleCopy(value)}
          >
            <CopyIcon size={18} />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          {copyResults.success
            ? `${copiedText} copied!`
            : `Error copying: ${copyResults.error}`}
        </PopoverContent>
      </Popover>
    </div>
  );
};
export const DebugInfo = ({
  cardData,
  open,
  setOpen,
  shop,
  firestoreId,
}: DebugInfoProps) => {
  const desiredContainers = useMemo(
    () => card.getContainerOptions(cardData, shop?.containers ?? []),
    [shop, cardData]
  );

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Card Debug Info</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <CardField name="Firestore ID" value={firestoreId} />
          <CardField name="Shop ID" value={shop?.id} />
          {shop?.tenantId && (
            <CardField name="Tenant ID" value={shop?.tenantId} />
          )}
          <CardField name="Card ID" value={cardData.id} />
          <CardField name="Repair Order Number" value={cardData.cardNumber} />
          <CardField
            name="Created Via"
            value={capitalize(cardData.source ?? "Unknown")}
          />
          <CardField name="Technician ID" value={cardData.technicianId} />
          <CardField name="Label" value={cardData.label} />
        </div>
        <h2 className={"text-primary text-lg font-bold"}>Card Sync Options</h2>
        <CardField name="Current Container" value={cardData.currentContainer} />
        <CardField
          name="Sync Tech Only"
          value={desiredContainers.syncTechOnly
            .map((t, i) => `${i + 1}. ${t}`)
            .join(",\n ")}
        />
        <CardField
          name="Sync Label Only"
          value={desiredContainers.syncLabelOnly
            .map((t, i) => `${i + 1}. ${t}`)
            .join(",\n ")}
        />
        <CardField
          name="Sync Tech & Label"
          value={desiredContainers.syncBoth
            .map((t, i) => `${i + 1}. ${t}`)
            .join(",\n ")}
        />
      </DialogContent>
    </Dialog>
  );
};
