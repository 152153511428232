import { ReactNode, useEffect, useState } from "react";
import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";

interface CardRemoveDialogProps {
  onRemove: () => void;
  trigger?: ReactNode;
  externalTrigger?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}
export const CardRemoveDialog = ({
  onRemove,
  trigger,
  externalTrigger,
  open,
  onOpenChange,
}: CardRemoveDialogProps) => {
  const [isOpen, setIsOpen] = useState(open ?? false);

  // Sync local state with the controlled `open` prop
  useEffect(() => {
    if (typeof open === "boolean") {
      setIsOpen(open);
    }
  }, [open]);

  // Handle the dialog's open state changes
  const handleOpenChange = (newOpen: boolean) => {
    setIsOpen(newOpen);
    onOpenChange?.(newOpen); // Notify parent if `onOpenChange` is provided
  };

  const handleContinue = () => {
    onRemove();
    handleOpenChange(false);
  };

  const handleCancel = () => {
    handleOpenChange(false);
  };

  return (
    <Dialog onOpenChange={handleOpenChange} open={isOpen}>
      {trigger && !externalTrigger ? (
        // Use custom trigger if provided
        <DialogTrigger asChild>{trigger}</DialogTrigger>
      ) : (
        // Default trigger button
        !externalTrigger && (
          <DialogTrigger asChild>
            <Button onClick={() => handleOpenChange(true)}>Remove Card</Button>
          </DialogTrigger>
        )
      )}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Remove</DialogTitle>
        </DialogHeader>
        <div>
          This will remove the card from the dashboard. Are you sure you want to
          do this?
        </div>
        <DialogFooter>
          <Button type="button" variant={"secondary"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant={"destructive"}
            onClick={handleContinue}
          >
            Remove
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
