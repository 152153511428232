import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import { Card, Job, CardTimer, Employee, Shop } from "@/types/v2";
import { Clock, Calendar, Tag, Car, ChartColumn } from "lucide-react";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Base/Tooltip";
import { Button } from "@/components/Base/Button";
import { firestoreToDateTime } from "@/lib/utils";
import { Duration, formatDuration, intervalToDuration } from "date-fns";
import { getCardSection } from "@/lib/cards";
import { getEmployeeName } from "@/lib/employees";

export interface CardMetricsProps {
  cardData: Card;
  fullButton?: boolean;
  trigger?: ReactNode;
  externalTrigger?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  employees?: Employee[];
  shop: Shop;
}

export const CardMetrics = ({
  cardData,
  fullButton,
  trigger,
  externalTrigger,
  open,
  onOpenChange,
  employees,
  shop,
}: CardMetricsProps) => {
  const [isOpen, setIsOpen] = useState(open ?? false);

  // Sync local state with the controlled `open` prop
  useEffect(() => {
    if (typeof open === "boolean") {
      setIsOpen(open);
    }
  }, [open]);

  // Handle the dialog's open state changes
  const handleOpenChange = (newOpen: boolean) => {
    setIsOpen(newOpen);
    onOpenChange?.(newOpen); // Notify parent if `onOpenChange` is provided
  };

  const approvedJobs = useMemo(() => {
    if (!cardData.jobs) return [];
    return cardData.jobs.filter((job: Job) => job.authorized === true);
  }, [cardData]);

  const completedJobs = useMemo(() => {
    if (!cardData.jobs) return [];
    return cardData.jobs.filter((job: Job) => job.completedDate != null);
  }, [cardData]);

  const sumHoursOnJobs = (jobs: Job[]) => {
    return jobs.reduce((acc, curr) => acc + (curr.laborHours ?? 0), 0);
  };

  const getHoursDisplay = (jobs: Job[]) => {
    if (!jobs) return "No jobs on RO";
    const totalHours = sumHoursOnJobs(approvedJobs);
    const completedHours = sumHoursOnJobs(completedJobs);

    const percentComplete = ((completedHours / totalHours) * 100).toFixed(0);

    if (totalHours == 0) return "No labor hours on RO";

    return `${completedHours.toFixed(2)} / ${totalHours.toFixed(2)} (${percentComplete}%)`;
  };

  const getDateFromDateTimeOrSeconds = (
    dateTime: Date | { seconds: number; nanoseconds?: number }
  ): Date => {
    if (typeof dateTime === "object" && "seconds" in dateTime) {
      const utcDate = new Date(dateTime.seconds * 1000); // Firestore timestamp case
      return new Date(
        utcDate.toLocaleString("en-US", { timeZone: "America/New_York" })
      ); // Convert to local time
    } else {
      const utcDate = new Date(dateTime); // Date object or date string
      return new Date(
        utcDate.toLocaleString("en-US", { timeZone: "America/New_York" })
      ); // Convert to local time
    }
  };

  const calculateDurationInCurrentContainer = (containerInfo: CardTimer) => {
    const enteredAt = firestoreToDateTime(containerInfo.enteredAt) as Date; // We can say it is a date because enteredAt is always defined
    const exitedAt = firestoreToDateTime(
      containerInfo.exitedAt ?? new Date()
    ) as Date; // Wer are making sure it is defined
    return intervalToDuration({
      start: enteredAt,
      end: exitedAt,
    });
  };

  const customFormatDuration = (duration: Duration) => {
    if (
      !duration.years &&
      !duration.months &&
      !duration.days &&
      !duration.hours
    ) {
      return formatDuration(duration, { format: ["minutes", "seconds"] });
    } else if (!duration.years && !duration.months && !duration.days) {
      if (!duration.minutes) {
        return formatDuration(duration, { format: ["hours", "seconds"] });
      }
      return formatDuration(duration, { format: ["hours", "minutes"] });
    } else if (!duration.years && !duration.months) {
      if (!duration.hours && !duration.minutes)
        return formatDuration(duration, { format: ["days", "seconds"] });
      else if (!duration.hours)
        return formatDuration(duration, { format: ["days", "minutes"] });
      return formatDuration(duration, { format: ["days", "hours"] });
    } else if (!duration.years) {
      if (!duration.days && !duration.hours && !duration.minutes)
        return formatDuration(duration, { format: ["months", "seconds"] });
      else if (!duration.days && !duration.hours)
        return formatDuration(duration, { format: ["months", "minutes"] });
      else if (!duration.days)
        return formatDuration(duration, { format: ["months", "hours"] });
      return formatDuration(duration, { format: ["months", "days"] });
    }

    return formatDuration(duration, { format: ["years", "months"] });
  };

  const getTotalTimeInShop = (history: Card["history"]) => {
    if (!history) return "No history available";

    const enteredAt = getDateFromDateTimeOrSeconds(history[0].enteredAt);
    const currentDateTime = new Date();
    const duration = intervalToDuration({
      start: enteredAt,
      end: currentDateTime,
    });
    return customFormatDuration(duration);
  };

  return (
    <Dialog onOpenChange={handleOpenChange} open={isOpen}>
      {trigger && !externalTrigger ? (
        // Use custom trigger if provided
        <DialogTrigger asChild>{trigger}</DialogTrigger>
      ) : (
        // Default trigger button
        !externalTrigger && (
          <DialogTrigger asChild={fullButton}>
            {fullButton ? <Button>Card Metrics</Button> : <ChartColumn />}
          </DialogTrigger>
        )
      )}
      <DialogContent className="gap-1 m-0 p-0 max-h-screen flex flex-col">
        <DialogTitle>
          {/* Header */}
          <div
            className="w-full h-10 rounded-t-lg flex items-center justify-center text-primary"
            style={{ backgroundColor: cardData.color }}
          >
            {getHoursDisplay(cardData.jobs ?? [])}
          </div>
        </DialogTitle>

        {/* Scrollable Content */}
        <div className="p-4 flex-1 overflow-y-auto">
          <TooltipProvider key={"update"}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="text-3xl font-bold">
                  {getCardSection(cardData, shop, "primaryField")}
                </div>
              </TooltipTrigger>
              <TooltipContent>{`RO ID: ${cardData?.id}`}</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <div className="text-2xl">
            {getCardSection(cardData, shop, "secondaryField")}
          </div>
          <div className="text-xl">
            {getCardSection(cardData, shop, "tertiaryField")}
          </div>

          {/* Appointment and Due Date - Not applicable to Manual Cards*/}
          {cardData?.source !== "manual" && (
            <Fragment>
              <div className="text-xl font-bold mt-4 border-t"></div>

              <div className="flex flex-row grid-cols-3 mt-4">
                <div className="flex flex-row gap-2 items-center">
                  <Clock size={16} />
                  Appointment:{" "}
                  {cardData?.appointment?.startAt
                    ? firestoreToDateTime(
                        cardData?.appointment?.startAt
                      )?.toLocaleString()
                    : "No appointment set"}
                  {cardData?.appointment?.endAt &&
                    cardData?.appointment?.startAt &&
                    " - "}
                  {firestoreToDateTime(
                    cardData?.appointment?.endAt
                  )?.toLocaleString()}
                </div>
              </div>
              <div className="flex flex-row grid-cols-3">
                <div className="flex flex-row gap-2 items-center">
                  <Calendar size={16} />
                  Due Date:{" "}
                  {firestoreToDateTime(
                    cardData.promisedTime
                  )?.toLocaleString() ?? "No due date set"}
                </div>
              </div>

              <div className="flex flex-row grid-cols-3">
                <div className="flex flex-row gap-2 items-center">
                  <Tag size={16} />
                  Label:
                  <div
                    className="flex justify-center text-white font-bold px-2 rounded-lg"
                    style={{ backgroundColor: cardData?.backgroundColor }}
                  >
                    {cardData?.label}
                  </div>
                </div>
              </div>

              <div className="flex flex-row grid-cols-3">
                <div className="flex flex-row gap-2 items-center">
                  <Car size={16} />
                  Waiter: {cardData?.isWaiter ? "Yes" : "No"}
                </div>
              </div>
            </Fragment>
          )}

          {(cardData?.technicianId !== "none" ||
            cardData?.serviceWriterId !== "none") && (
            <Fragment>
              <div className="text-xl font-bold mt-4 border-t"></div>

              <div className="grid grid-cols-2 mt-1">
                <div className="flex flex-col gap-1">
                  <div className="font-bold">Technician</div>
                  <div>
                    {getEmployeeName(cardData?.technicianId, employees ?? [])}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="font-bold">Service Advisor</div>
                  <div>
                    {getEmployeeName(
                      cardData?.serviceWriterId,
                      employees ?? []
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {/* RO Report */}
          <div className="text-xl font-bold mt-4 border-t">RO Report</div>

          {/* Header Row */}
          {cardData?.history?.length > 0 ? (
            <>
              <div className="grid grid-cols-[2fr_1fr] font-semibold pb-2">
                <div className="text-left">Container ID</div>
                <div className="text-left">Duration</div>
              </div>

              {/* Data Rows */}
              <div className="overflow-y-auto max-h-96">
                {cardData?.history?.map((containerInfo, index) => (
                  <div key={index} className="grid grid-cols-3 gap-2">
                    <div className="text-left">
                      {containerInfo.currentContainerId}
                    </div>
                    <div className="col-span-2 text-right w-full">
                      {customFormatDuration(
                        calculateDurationInCurrentContainer(containerInfo)
                      )}
                    </div>
                    <div className="flex items-center"></div>
                  </div>
                ))}
              </div>

              {/* Footer */}
              <div className="grid grid-cols-3 font-semibold pb-2">
                {/* TODO: Calculate total time */}
                <div className="text-left">Total Container Time</div>
                <div className="text-right col-span-2 w-full">
                  {getTotalTimeInShop(cardData.history)}
                </div>
              </div>
            </>
          ) : (
            <div className="text-center">No container history available</div>
          )}
        </div>

        {/* Footer */}
        <div className="flex items-center mt-4 bg-black text-white h-10 rounded-b-lg justify-center">
          <Clock className="pr-2" /> Total Time in Shop{" "}
          {getTotalTimeInShop(cardData.history)}
        </div>
      </DialogContent>
    </Dialog>
  );
};
