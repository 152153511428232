import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { cn, getTextColor } from "@/lib/utils";
import { useFeature } from "flagged";
import { Card as CardType } from "@/types/v2";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "@/stores/useStore";
import { CardFooter } from "@/components/Dashboard/components/Card/CardFooter";
import { CardHeader } from "@/components/Dashboard/components/Card/Card.Header";
import { CardMain } from "@/components/Dashboard/components/Card/Card.Main";
import { getCardSection } from "@/lib/cards";
import { useRoles } from "@/hooks/useRoles";
import { CardSidebar } from "@/components/Dashboard/components/Card/Card.Sidebar";
import { useCardAnimations } from "@/hooks/useCardAnimations";

interface CardProps {
  cardId: string;
  ticker: number;
  isDragging?: boolean;
  tentative?: boolean;
}

export const Card = ({ cardId, ticker, isDragging, tentative }: CardProps) => {
  const shopId = useStore((s) => s.shopId);
  const shop = useStore((s) => s.shop);
  const timers = useStore((s) => s.timers);
  const employees = useStore((s) => s.employees);
  const timer = useMemo(
    () => (timers ? timers[cardId] : undefined),
    [cardId, timers]
  );
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: cardId });
  const { isViewOnly } = useRoles();

  const [startTime, setStartTime] = useState<"stop" | number | null>(null);

  const isCardColorEnabled = useFeature("isCardColorEnabled") as boolean;
  const isCardColorSyncEnabled = useFeature(
    "isCardColorSyncEnabled"
  ) as boolean;
  const isPromiseTimeEnabled = useFeature("isPromiseTimeEnabled") as boolean;

  const [cardData, setCardData] = useState<CardType>();

  const { cards } = useStore();

  useEffect(() => {
    if (!timer) {
      if (typeof startTime === "number") setStartTime(null);
    }
    if (timer) {
      if (!startTime || startTime === "stop") {
        setStartTime(timer.timeStarted);
      }
    }
  }, [startTime, timer]);

  useEffect(() => {
    if (!shopId) return;
    setCardData(cards.find((card) => card.id === cardId));
  }, [cardId, shopId, cards]);

  const { animationName, getBackgroundColor, automationAction } =
    useCardAnimations({
      cardData: cardData,
      isCardColorSyncEnabled: isCardColorSyncEnabled ?? false,
      isCardColorEnabled: isCardColorEnabled ?? false,
      isPromiseTimeEnabled: isPromiseTimeEnabled ?? false,
      isDragging: isDragging ?? false,
      startTime,
    });

  useEffect(() => {
    if (cardData?.id === "1131014") {
      console.log("cardData", cardData);
      console.log(
        automationAction("flash"),
        cardData.automationAction === "flash"
      );
    }
  }, [cardData]);

  if (!shopId || !cardData || !shop) return null;

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "transition-all cursor-auto flex flex-col rounded-md flex-1 text-sm min-w-[150px] max-w-[150px] items-start mb-1 ml-1 mr-1 shadow-2xl",
        isDragging && "rotate-6 shadow-2xl border opacity-75 scale-90",
        tentative && "shadow-2xl border opacity-75",
        automationAction("wiggle") && "animate-wiggle"
      )}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundColor: getBackgroundColor(),
        color: getTextColor(getBackgroundColor()),
        animation: automationAction("flash")
          ? `${animationName} 2s infinite ease-in-out`
          : "",
      }}
    >
      <div className="w-full flex flex-col flex-grow relative">
        <div className="w-full flex flex-col flex-grow-0 overflow-hidden">
          <CardHeader
            disabled={isViewOnly}
            cardData={cardData}
            cardId={cardId}
            primaryLabel={getCardSection(cardData, shop, "primaryField")}
            dndAttributes={attributes}
            dndListeners={listeners}
          />
          <CardMain
            cardData={cardData}
            cardId={cardId}
            shop={shop}
            shopId={shopId}
            ticker={ticker}
            dndAttributes={attributes}
            dndListeners={listeners}
          />
          <CardSidebar
            shopId={shopId}
            cardId={cardId}
            cardData={cardData}
            employees={employees}
            disabled={isViewOnly}
            shop={shop}
          />
        </div>
        {/* PRIMARY LABEL */}

        {/* Center of button */}
      </div>

      <CardFooter
        disabled={isViewOnly}
        ticker={ticker}
        cardData={cardData}
        shopId={shopId}
        startTime={startTime}
        bgColor={getBackgroundColor(true)}
      />
    </div>
  );
};
