import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Base/Popover";
import { Card, Employee } from "@/types/v2";
import {
  getEmployeeName,
  getEmployeeInitials,
  getServiceAdvisorColor,
} from "@/lib/employees";
import { cn } from "@/lib/utils";

export interface CardTechSALabelProps {
  cardData: Card;
  employees?: Employee[];
}

export const CardTechSALabel = ({
  cardData,
  employees = [],
}: CardTechSALabelProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={cn(
          "cursor-pointer z-5 p-0 rounded-full text-accent hover:scale-105"
        )}
        onMouseDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="w-5 h-5 rounded-full flex justify-center items-center border border-white"
          style={{
            backgroundColor: getServiceAdvisorColor(
              cardData?.serviceWriterId,
              employees || []
            ),
          }}
        >
          <div className="text-white text-[10px] font-bold">
            {getEmployeeInitials(cardData?.technicianId, employees || [])}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        onMouseDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-2 p-2"
      >
        <div>
          <p>
            <strong>Service Advisor:</strong>{" "}
            {cardData?.serviceWriterId
              ? getEmployeeName(cardData?.serviceWriterId, employees || [])
              : "N/A"}
          </p>
          <p>
            <strong>Technician:</strong>{" "}
            {cardData?.technicianId
              ? getEmployeeName(cardData?.technicianId, employees || [])
              : "N/A"}
          </p>
        </div>
      </PopoverContent>
    </Popover>
  );
};
