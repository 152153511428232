import { Card as CardModel, Card } from "@/types/v2";
import React, { useEffect, useMemo } from "react";
import { firestoreToDateTime, isPast } from "@/lib/utils";
import {
  DEFAULT_CARD_COLOR,
  DEFAULT_TIMER_VALUE,
  PROMISE_TIME_PAST_COLOR,
} from "@/lib/const";

export interface CardAnimationsProps {
  cardData: Card | undefined;
  isPromiseTimeEnabled: boolean;
  isCardColorSyncEnabled: boolean;
  isCardColorEnabled: boolean;
  isDragging: boolean;
  startTime: number | null | "stop";
}
export const useCardAnimations = ({
  cardData,
  isCardColorSyncEnabled,
  isCardColorEnabled,
  isPromiseTimeEnabled,
  isDragging,
  startTime,
}: CardAnimationsProps) => {
  const cardBackgroundColor = React.useMemo(() => {
    if (isPromiseTimeEnabled && isPast(cardData?.promisedTime)) {
      return PROMISE_TIME_PAST_COLOR;
    } else if (isCardColorSyncEnabled && cardData?.backgroundColor) {
      return cardData.backgroundColor;
    } else if (isCardColorEnabled && cardData?.color) {
      return cardData.color;
    } else if (isCardColorEnabled && cardData?.bgColor) {
      // TODO: REMOVE EVENTUALLY
      return cardData.bgColor;
    }

    return DEFAULT_CARD_COLOR;
  }, [
    cardData,
    isPromiseTimeEnabled,
    isCardColorSyncEnabled,
    isCardColorEnabled,
  ]);

  const getBackgroundColor = (excludeAutomation: boolean = false) => {
    const theColor = !isCardColorEnabled
      ? startTime !== null && startTime !== "stop"
        ? Date.now() - startTime >
          parseInt(cardData?.timer?.timeLimitInMinutes ?? DEFAULT_TIMER_VALUE) *
            60 *
            1000
          ? "#FF0000"
          : "#FFFF00"
        : cardBackgroundColor
      : cardBackgroundColor;

    if (
      automationActionActive() &&
      automationAction("color") &&
      cardData?.automationActionData &&
      !excludeAutomation
    ) {
      return cardData?.automationActionData;
    } else if (isPromiseTimeEnabled && isPast(cardData?.promisedTime)) {
      return PROMISE_TIME_PAST_COLOR;
    } else if (isCardColorSyncEnabled && cardData?.backgroundColor) {
      return cardData.backgroundColor;
    } else if (isCardColorEnabled && cardData?.color) {
      return cardData.color;
    }
    return theColor;
  };

  const automationActionActive = () => {
    const now = new Date().getTime();
    let startsAt = 0;
    if (cardData?.automationActionStartAt) {
      startsAt =
        firestoreToDateTime(cardData?.automationActionStartAt)?.getTime() ?? 0;
    }
    if (cardData?.automationActionExpireAt) {
      const expiresAt = firestoreToDateTime(
        cardData?.automationActionExpireAt
      )?.getTime();

      if (expiresAt && expiresAt <= now) {
        // The action has expired, return false
        return false;
      }
    }

    return !(startsAt && startsAt > now);
  };

  const automationAction = (
    action: CardModel["automationAction"]
  ): null | boolean => {
    if (!automationActionActive() || !cardData || !cardData?.automationAction) {
      return null;
    }

    switch (action) {
      case "wiggle":
        if (
          !isDragging &&
          ["wiggle", "colorAndWiggle"].includes(cardData.automationAction)
        ) {
          return true;
        }
        break;
      case "color":
        if (["color", "colorAndWiggle"].includes(cardData.automationAction)) {
          return true;
        }
        break;
      default:
        return cardData.automationAction === action;
    }

    return null;
  };

  const colors = useMemo(() => {
    const bgColor = getBackgroundColor(true);
    if (cardData?.automationAction === "flash") {
      return [...(cardData?.automationActionData?.split(",") ?? []), bgColor];
    }
    return [];
  }, [
    cardData?.automationAction,
    cardData?.automationActionData,
    getBackgroundColor,
  ]);

  const animationName = `dynamicFlash-${cardData?.id ?? "tbd"}`;

  useEffect(() => {
    // Define dynamic keyframe styles
    const keyframes = `
      @keyframes ${animationName} {
        ${colors
          .map((color, i) => {
            return `${(i / colors.length) * 100}% { background-color: ${color}; }`;
          })
          .join("\n")}          
        100% { background-color: ${colors[0]}; } /* Loop back to the first color */

      }
    `;

    // Inject keyframes into a style element in the document
    const styleSheet =
      document.styleSheets[0] ||
      document.head.appendChild(document.createElement("style")).sheet;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

    return () => {
      // Clean up by removing the custom keyframe rule (if necessary in reactive scenarios)
      const rules = Array.from(styleSheet.cssRules);
      const ruleIndex = rules.findIndex((rule) =>
        rule.cssText.startsWith(`@keyframes ${animationName}`)
      );
      if (ruleIndex > -1) {
        styleSheet.deleteRule(ruleIndex);
      }
    };
  }, [colors, animationName]);

  return {
    colors,
    animationName,
    getBackgroundColor,
    automationAction,
  };
};
